import React from 'react';
import PropTypes from 'prop-types';

import { getRandomFallbackProfilePicture } from '../../common/AssetManager';
import GlintsPicture from './GlintsPicture';

const ProfilePicture = ({ userID, profilePic, ...otherProps }) => {
  if (!userID) {
    return null;
  }

  const fallback = getRandomFallbackProfilePicture(userID);
  const fallbackAssetType = 'profile-picture-default';

  return (
    <GlintsPicture
      name={profilePic}
      assetType="profile-picture"
      fallback={fallback}
      fallbackAssetType={fallbackAssetType}
      aspectRatio={1}
      {...otherProps}
    />
  );
};

ProfilePicture.propTypes = {
  userID: PropTypes.any.isRequired,
  profilePic: PropTypes.string,
};

export default ProfilePicture;
