import React from 'react';
import { useIntl } from 'react-intl';

import { messages } from './constant';
import * as S from './styles.sc';

export const WhatsappButton = () => {
  const intl = useIntl();

  const prefilledMessage = encodeURIComponent(
    intl.formatMessage(messages.textHello)
  );

  return (
    <S.StyledWhatsappButton
      href={`https://wa.me/6289510171504?text=${prefilledMessage}`}
    >
      <img alt="Chat on WhatsApp" src="images/whatsapp.svg" />
    </S.StyledWhatsappButton>
  );
};
