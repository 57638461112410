import { defineMessages } from 'react-intl';

import { Status } from 'src/global/models/Application';

export enum ApplicationStatusQuery {
  All = 'All',
  PendingReview = 'Pending Review',
  Shortlisted = 'Shortlisted',
  Interviewing = 'Interviewing',
  Assessment = 'Assessment',
  Offered = 'Offered',
  Hired = 'Hired',
  Unsuitable = 'Unsuitable',
}

export type ChatApplicationStatusType =
  | ApplicationStatusQuery.PendingReview
  | ApplicationStatusQuery.Shortlisted
  | ApplicationStatusQuery.Unsuitable;

export const applicationStatusMessages = defineMessages({
  [ApplicationStatusQuery.All]: {
    id: 'tab-group_all',
    defaultMessage: 'All',
  },
  [ApplicationStatusQuery.PendingReview]: {
    id: 'application.status.pending-review',
    defaultMessage: 'Applied',
  },
  [ApplicationStatusQuery.Shortlisted]: {
    id: 'application.status.shortlisted',
    defaultMessage: 'Under Review',
  },
  [ApplicationStatusQuery.Assessment]: {
    id: 'application.status.assessment',
    defaultMessage: 'Skill Test',
  },
  [ApplicationStatusQuery.Interviewing]: {
    id: 'application.status.interviewing',
    defaultMessage: 'Interviewing',
  },
  [ApplicationStatusQuery.Offered]: {
    id: 'application.status.offered',
    defaultMessage: 'Offer Received',
  },
  [ApplicationStatusQuery.Hired]: {
    id: 'application.status.hired',
    defaultMessage: 'Hired',
  },
  [ApplicationStatusQuery.Unsuitable]: {
    id: 'application.status.unsuitable',
    defaultMessage: 'Rejected',
  },
});

export const applicationStatusMessagesChat = defineMessages({
  [ApplicationStatusQuery.PendingReview]: {
    id: 'text_application-status_chat-initiated-new',
    defaultMessage: 'Chat Initiated',
  },
  [ApplicationStatusQuery.Shortlisted]: {
    id: 'text_application-status_in-communication-new',
    defaultMessage: 'In Communication',
  },
  [ApplicationStatusQuery.Unsuitable]: {
    id: 'text_application-status_unsuitable-new',
    defaultMessage: 'Not Suitable',
  },
});

export const applicationStatusQueryAndApplicationStatusMap: {
  [key: string]: string;
} = {
  [ApplicationStatusQuery.All]: 'ALL',
  [ApplicationStatusQuery.PendingReview]: Status.NEW,
  [ApplicationStatusQuery.Shortlisted]: Status.IN_REVIEW,
  [ApplicationStatusQuery.Interviewing]: Status.INTERVIEWING,
  [ApplicationStatusQuery.Assessment]: Status.ASSESSMENT,
  [ApplicationStatusQuery.Offered]: Status.OFFERED,
  [ApplicationStatusQuery.Hired]: Status.HIRED,
  [ApplicationStatusQuery.Unsuitable]: Status.REJECTED,
};

export enum applicationSortingOption {
  // TODO: Update RESTCollectionController to enable this
  // AToZ = 'A to Z',
  MostRecent = 'Most Recent',
  Oldest = 'Oldest',
}

export enum targetId {
  OneMonth = '49e0ef29-90ff-4bd7-ba5c-32b179624c32',
  TwoMonths = '5960fcc0-b62d-41fc-905c-9c1d5261186a',
  ThreeMonths = '7d4d801d-8ab5-4310-a93f-97003f6e69ec',
}

export const targetRecommendedApplicationsPerWeek: { [key: string]: number } = {
  [targetId.OneMonth]: 24,
  [targetId.TwoMonths]: 12,
  [targetId.ThreeMonths]: 8,
};

export const targetMessageMap: { [key: string]: string } = {
  [targetId.OneMonth]: 'In 1 month',
  [targetId.TwoMonths]: 'In 2 months',
  [targetId.ThreeMonths]: 'In 3 months',
};

export const messageIds: { [key: string]: string } = {
  [targetId.OneMonth]: 'applications-page_in-one-month',
  [targetId.TwoMonths]: 'applications-page_in-two-months',
  [targetId.ThreeMonths]: 'applications-page_in-three-months',
};
