import { CountryCodes } from 'src/common/enums';

/* get glints blog url by country code */
export const getBlogUrl = (countryCode: CountryCodes = null) => {
  switch (countryCode) {
    case CountryCodes.ID:
      return '/id/lowongan/';
    case CountryCodes.VN:
      return '/vn/blog/';
    case CountryCodes.TW:
      return '/tw/blog/';
    default:
      return '/sg/hired/';
  }
};

export const getMarketPlaceUrl = (
  countryCode: CountryCodes | string = null
) => {
  switch (countryCode) {
    case CountryCodes.ID:
      return 'https://employers.glints.id/job-search';
    case CountryCodes.VN:
      return 'https://employers.glints.vn/job-search';
    case CountryCodes.TW:
      return 'https://employers.glints.tw/job-search';
    case CountryCodes.MY:
      return 'https://employers.glints.my/job-search';
    // TODO not sure, probably should be added to countries list?
    case 'HK':
      return 'https://employers.glints.hk/job-search';
    default:
      return 'https://employers.glints.sg/job-search';
  }
};
export const getTalentHubUrl = (countryCode: CountryCodes | string = null) => {
  switch (countryCode) {
    case CountryCodes.ID:
      return 'https://employers.glints.sg';
    case CountryCodes.VN:
      return 'https://employers.glints.vn';
    case CountryCodes.TW:
      return 'https://employers.glints.tw';
    case CountryCodes.MY:
      return 'https://employers.glints.my';
    // TODO not sure, probably should be added to countries list?
    case 'HK':
      return 'https://employers.glints.hk';
    default:
      return 'https://talenthub.glints.com';
  }
};
export const getExploreUrl = (countryCode: CountryCodes | string = null) => {
  switch (countryCode) {
    case CountryCodes.ID:
      return '/id';
    case CountryCodes.VN:
      return '/vn';
    case CountryCodes.TW:
      return '/tw';
    case CountryCodes.MY:
      return '/my';
    default:
      return '/sg';
  }
};
export const getAboutUsUrl = (countryCode: CountryCodes | string = null) => {
  switch (countryCode) {
    case CountryCodes.ID:
      return '/id/about';
    case CountryCodes.VN:
      return '/vn/about';
    case CountryCodes.TW:
      return '/tw/about';
    case CountryCodes.MY:
      return '/my/about';
    default:
      return '/sg/about';
  }
};
