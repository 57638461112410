import URL from 'url-parse';

export interface Size {
  width?: number;
  height?: number;
}

export interface ThumborImageOptions {
  serverURL: string;
  src: string;
  size?: Size;
  hmac?: string;
  trim?: boolean;
  trimSource?: 'bottom-right' | 'top-left';
  crop?: {
    topLeft: {
      x: number;
      y: number;
    };
    bottomRight: {
      x: number;
      y: number;
    };
  };
  fitIn?: boolean;
  horizontalAlign?: 'left' | 'center' | 'right';
  verticalAlign?: 'top' | 'middle' | 'bottom';
  smartCrop?: boolean;
  filters?: {
    name: string;
    args: string[] | number[];
  }[];
}

export function getThumborImageURL(options: ThumborImageOptions) {
  // Construct the thumbor URL parts.
  // Reference: http://thumbor.readthedocs.io/en/latest/usage.html
  const pathParts = [];

  if (options.hmac) {
    pathParts.push(options.hmac);
  } else {
    pathParts.push('unsafe');
  }

  if (options.trim) {
    let trimPart = 'trim';
    if (options.trimSource) {
      trimPart += `:${options.trimSource}`;
    }
    pathParts.push(trimPart);
  }

  if (options.crop) {
    const cropPart =
      `${options.crop.topLeft.x}x${options.crop.topLeft.y}:` +
      `${options.crop.bottomRight.x}x${options.crop.bottomRight.y}`;
    pathParts.push(cropPart);
  }

  if (options.fitIn) {
    pathParts.push('fit-in');
  }

  if (options.size) {
    const width = options.size.width || 0;
    const height = options.size.height || 0;
    pathParts.push(`${width}x${height}`);
  }

  if (options.horizontalAlign) {
    pathParts.push(options.horizontalAlign);
  }

  if (options.verticalAlign) {
    pathParts.push(options.verticalAlign);
  }

  if (options.smartCrop) {
    pathParts.push('smart');
  }

  if (options.filters && options.filters.length > 0) {
    const filterPart = options.filters.map(
      (filter: { args: any; name: string }) => {
        const argsPart =
          filter.args && filter.args.length > 0 ? filter.args.join(',') : '';
        return `${filter.name}(${argsPart})`;
      }
    );
    pathParts.push(`filters:${filterPart.join(':')}`);
  }

  // Append the upstream server URL.
  const upstreamURL = new URL(options.src);
  return `${options.serverURL}/${pathParts.join('/')}/${upstreamURL.hostname}${
    upstreamURL.pathname
  }`;
}
