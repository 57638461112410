import React, { useEffect } from 'react';
import { debounce } from 'lodash';
import dynamic from 'next/dynamic';

import { GoogleOneTap } from 'src/components/GoogleOneTap';

import { DownloadAppPromotionModal } from '../CandidateMobileApp/Modals/DownloadAppPromotionModal';
import {
  MainContainerContext,
  MainHeaderHeightContext,
  MainHeaderStickyContext,
} from './Context';
import * as S from './MainContainer.sc';
import Navbar from './Navbar';
import Navigation from './Navbar/MenuMobileComponents/Navigation';

const DownloadMobileAppModal = dynamic(
  () =>
    import('src/modules/CandidateMobileApp/Modals/DownloadMobileAppModal').then(
      (module) => module.DownloadMobileAppModal
    ),
  { ssr: false }
);

const DownloadMobileAppBanner = dynamic(
  () =>
    import(
      'src/modules/CandidateMobileApp/Components/DownloadMobileAppBanner'
    ).then((module) => module.DownloadMobileAppBanner),
  { ssr: false }
);

const MainContainer: React.FC<
  React.PropsWithChildren<Record<string, unknown>>
> = ({ children }) => {
  const ref = React.useRef(null);

  const [headerHeight, setHeaderHeight] = React.useState(0);

  const [isStickyHeader, setIsStickyHeader] = React.useState(
    S.MainHeader.defaultProps.isSticky
  );

  const updateHeaderHeight = React.useCallback(() => {
    if (ref.current) {
      setHeaderHeight(ref.current.clientHeight);
    }
  }, [setHeaderHeight]);

  const [AlternativeHeader, setAlternativeHeader] = React.useState(undefined);

  useEffect(
    function updateHeaderHeightOnMountAndOnWindowResize() {
      const debouncedUpdateHeaderHeight = debounce(updateHeaderHeight, 500);
      debouncedUpdateHeaderHeight();
      window.addEventListener('resize', debouncedUpdateHeaderHeight);
      return () =>
        window.removeEventListener('resize', debouncedUpdateHeaderHeight);
    },
    [updateHeaderHeight]
  );

  return (
    <Navigation>
      <MainContainerContext.Provider value={{ setAlternativeHeader }}>
        <MainHeaderHeightContext.Provider value={headerHeight}>
          <MainHeaderStickyContext.Provider
            value={{
              isStickyHeader,
              setIsStickyHeader,
            }}
          >
            <S.MainLayout>
              {AlternativeHeader ? (
                <>
                  <DownloadMobileAppBanner />
                  <AlternativeHeader ref={ref} />
                </>
              ) : (
                <>
                  <DownloadMobileAppBanner />
                  <S.MainHeader isSticky={isStickyHeader} ref={ref}>
                    <Navbar />
                  </S.MainHeader>
                </>
              )}
              <S.MainBody>
                <GoogleOneTap />
                <DownloadMobileAppModal />
                <DownloadAppPromotionModal>
                  {children}
                </DownloadAppPromotionModal>
              </S.MainBody>
            </S.MainLayout>
          </MainHeaderStickyContext.Provider>
        </MainHeaderHeightContext.Provider>
      </MainContainerContext.Provider>
    </Navigation>
  );
};

export default MainContainer;
