import React, { useEffect } from 'react';
import pick from 'lodash/pick';

import { windowProperties } from 'src/common/enums/windowProperties';
import { ThumborImageOptions } from 'src/common/getThumborImageURL';
import { useUrlUtils } from 'src/common/hooks/useUrlUtils';

import {
  ImgAttributeNames,
  ImgAttributes,
  ThumborImageOptionKeys,
  ThumborImageProps,
} from './interfaces';
import { DefaultBreakpoints, scaleBreakpointsForHighDPR } from './utils';

export const ThumborImage: React.FC<
  React.PropsWithChildren<
    ThumborImageProps & {
      isURLImage?: boolean;
    }
  >
> = (props) => {
  const { breakpoints, aspectRatio, onError, lazy, isURLImage } = props;
  const imgAttributes: ImgAttributes = pick(props, ImgAttributeNames);
  const thumborOptions: ThumborImageOptions = pick(
    props,
    ThumborImageOptionKeys
  );
  const { getThumborImageURL } = useUrlUtils();

  const intitialSrc = props.src;

  const _breakpoints = Array.isArray(breakpoints)
    ? breakpoints
    : scaleBreakpointsForHighDPR(breakpoints);

  const srcSet = _breakpoints
    .map((breakpoint) => {
      const url = isURLImage
        ? intitialSrc
        : getThumborImageURL({
            ...thumborOptions,
            size: {
              height: aspectRatio ? Math.ceil(breakpoint / aspectRatio) : 0,
              width: breakpoint,
            },
          });
      return `${url} ${breakpoint}w`;
    })
    .join(', ');

  const src = isURLImage ? props.src : getThumborImageURL(thumborOptions);

  // SSRed image can be failed loaded before React's hydration,
  // so the attached onError handler will not be run.
  // to solve this, we add an error event listener in _document.tsx
  // to store an img error map in the window object,
  // and execute onError when necessary
  useEffect(
    function componentDidMount() {
      if (
        window[windowProperties.__IMAGE_ERROR__] &&
        window[windowProperties.__IMAGE_ERROR__][src] &&
        typeof onError === 'function'
      ) {
        onError();
      }
    },
    [onError, src]
  );

  return (
    <img
      {...imgAttributes}
      src={src}
      srcSet={srcSet}
      loading={lazy ? 'lazy' : undefined}
      onError={onError}
    />
  );
};

ThumborImage.defaultProps = {
  breakpoints: DefaultBreakpoints,
  lazy: true,
};
