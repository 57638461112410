import { createSelector } from 'reselect';

import { State } from 'src/global/store';
import { UnleashFeatureNames } from 'src/modules/Unleash/constants';
import { getFeature } from 'src/modules/Unleash/Selectors';
import { getApplications, getOpportunities } from 'src/selectors/api';

const getUserApplications = (state: State) => state.applications;

export const getMyApplicationIds = (state: State) =>
  getUserApplications(state).value;

export const getMyApplications = createSelector(
  [getMyApplicationIds, getApplications],
  (ids, applications) => ids.map((id) => applications[id])
);

const getMyApplicationsOpportunityIds = (state: State) =>
  getUserApplications(state).opportunities.value;

const getMyApplicationsOpportunities = createSelector(
  [getMyApplicationsOpportunityIds, getOpportunities],
  (ids, opportunities) => ids.map((id) => opportunities[id])
);

export const getMyApplicationsCompanyIds = createSelector(
  [getMyApplicationsOpportunities],
  (opportunities) => opportunities.map((opportunity) => opportunity.CompanyId)
);

export const getUserApplicationsLoading = (state: State) =>
  getUserApplications(state).loading;

export const getUserApplicationsTotalPages = (state: State) =>
  getUserApplications(state).totalPages;

export const getUserApplicationsTotalCount = (state: State) =>
  getUserApplications(state).total;

export const getUserApplicationsItemsPerPage = (state: State) =>
  getUserApplications(state).itemsPerPage;

export const getIsApplicationPageBannerHidden = (state: State): boolean =>
  Boolean(getFeature(state, UnleashFeatureNames.dstHideApplicationPageBanner));

export const getIsApplicationPageArchiveTitleHidden = (state: State): boolean =>
  Boolean(
    getFeature(state, UnleashFeatureNames.dstHideApplicationPageArchiveTitle)
  );
