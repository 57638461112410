import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { createUrlUtils } from 'src/common/urlUtils/urlUtils';
import { getConfig } from 'src/modules/ClientConfig';
import { getIsAllUseOSSEnabled } from 'src/modules/Unleash/Selectors';

export const useUrlUtils = () => {
  const config = useSelector(getConfig);
  const isAllUseOssEnabled = useSelector(getIsAllUseOSSEnabled);
  const urlUtils = useMemo(
    () => createUrlUtils(config, isAllUseOssEnabled),
    [config, isAllUseOssEnabled]
  );
  return urlUtils;
};
