import React from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import GlintsContainer from 'src/components/GlintsContainer';
import LoginSignUpModal from 'src/components/LoginModal';
import { loginSignupModalTypes } from 'src/components/LoginModal/Constants';
import ReportModalV2 from 'src/components/ReportModalV2/ReportModalV2';
import { EBreakpoints, Media } from 'src/media';

import { MenuItemPropType } from './MenuItems';

const DesktopMenu = dynamic(
  () =>
    import(
      /* webpackChunkName: "MenuDesktopComponents" */ './MenuDesktopComponents'
    ),
  { ssr: false }
);

const MobileMenu = dynamic(
  () =>
    import(
      /* webpackChunkName: "MenuMobileComponents" */ './MenuMobileComponents'
    ),
  { ssr: false }
);

/**
 * The menu. Encapsulates the desktop and mobile menu.
 */
const MenuBase = props => {
  const { menuItems, desktopMenuPosition, authenticated } = props;

  const mobileMenuProps = {
    menuItems: menuItems.filter(
      item => item.visibleOnViewport?.includes('mobile') ?? true
    ),
    authenticated,
  };

  const desktopMenuItems = menuItems.filter(
    item => item.visibleOnViewport?.includes('desktop') ?? true
  );

  const desktopMenuProps = {
    authenticated,
    menuItems: {
      left: desktopMenuPosition === 'left' ? desktopMenuItems : [],
      right: desktopMenuPosition === 'right' ? desktopMenuItems : [],
    },
  };

  return (
    <GlintsContainer className="max-height">
      <LoginSignUpModal variant={loginSignupModalTypes.generic} />
      <ReportModalV2 />
      <Media lessThan={EBreakpoints.desktopS}>
        <MobileMenu {...mobileMenuProps} />
      </Media>
      <Media greaterThanOrEqual={EBreakpoints.desktopS}>
        <DesktopMenu {...desktopMenuProps} />
      </Media>
    </GlintsContainer>
  );
};

MenuBase.propTypes = {
  className: PropTypes.string,
  authenticated: PropTypes.bool,
  desktopMenuPosition: PropTypes.oneOf(['left', 'right']),
  hideMenu: PropTypes.bool,
  menuItems: PropTypes.arrayOf(MenuItemPropType).isRequired,
};

MenuBase.defaultProps = {
  desktopMenuPosition: 'left',
  hideMenu: false,
};

export default MenuBase;
