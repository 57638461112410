import { EducationLevel } from 'src/modules/Opportunity/interface';
import { HierarchicalJobCategory } from 'src/modules/Profile/graphql/jobCategoriesAndTitles';

import { ResourceLinks } from './ResourceLinks';

export enum JobStatus {
  OPEN = 'OPEN',
  IN_REVIEW = 'IN_REVIEW',
  CLOSED = 'CLOSED',
}

export enum JobType {
  FULL_TIME = 'FULL_TIME',
  INTERNSHIP = 'INTERNSHIP',
  PART_TIME = 'PART_TIME',
  PROJECT_BASED = 'PROJECT_BASED',
  DAILY = 'DAILY',
  CONTRACT = 'CONTRACT',
}

export enum EBenefits {
  CAREER_GROWTH = 'CAREER_GROWTH',
  HOUSING = 'HOUSING',
  WORK_STATION = 'WORK_STATION',
  TRAVEL = 'TRAVEL',
  MEDICAL_COVERAGE = 'MEDICAL_COVERAGE',
  STOCK_OPTION = 'STOCK_OPTION',
  WELL_NESS = 'WELL_NESS',
  LIFETIME_SAVINGS = 'LIFETIME_SAVINGS',
  INSURANCE = 'INSURANCE',
  VACATION_AND_LEAVES = 'VACATION_AND_LEAVES',
  GIFTS_AND_VOUCHERS = 'GIFTS_AND_VOUCHERS',
  LOANS = 'LOANS',
  FLEXIBLE_WORK_HOURS = 'FLEXIBLE_WORK_HOURS',
  TEAM_BUILDING_EVENTS = 'TEAM_BUILDING_EVENTS',
  FREE_FOOD_AND_BEVERAGES = 'FREE_FOOD_AND_BEVERAGES',
  REMOTE_WORK = 'REMOTE_WORK',
  PROFESSIONAL_DEVELOPMENT = 'PROFESSIONAL_DEVELOPMENT',
}

export const FraudReportFlag = {
  /**
   * Indicates that the ops team has verified that the job is indeed a scam or fraud.
   */
  VERIFIED_SCAM_FRAUD: 'VERIFIED_SCAM_FRAUD',

  /**
   * Indicates that the ops team has verified that the job is not a scam or fraud.
   */
  VERIFIED_NOT_SCAM_FRAUD: 'VERIFIED_NOT_SCAM_FRAUD',

  /**
   * Indicates that the job has been reported as a scam or fraud by a candidate.
   */
  REPORTED_SCAM_FRAUD: 'REPORTED_SCAM_FRAUD',
} as const;

export enum WorkArrangementOption {
  REMOTE = 'REMOTE',
  ONSITE = 'ONSITE',
  HYBRID = 'HYBRID',
}

export type FraudReportFlag =
  (typeof FraudReportFlag)[keyof typeof FraudReportFlag];
export interface Job extends ResourceLinks {
  id: string;
  title: string;
  status: JobStatus;
  startDate: string;
  endDate: string;
  bannerPic: string;
  type: JobType;
  expiryDate: string;
  minYearsOfExperience: number;
  maxYearsOfExperience: number;
  attachments: any;
  draftId: string;
  isCoverLetterMandatory: boolean;
  isPublic: boolean; // TODO: Whitelabel property - should be removed from the codebase
  numberOfHires: number;
  workArrangementOption: WorkArrangementOption;
  descriptionRaw: any;
  descriptionJsonString: string;
  interviewProcessJsonString?: string;
  CityId: number;
  CompanyId: string;
  CountryCode: string;
  CreatorId: string;
  externalApplyURL: string;
  closedAt: string;
  acceptsForeignApplications: boolean;
  createdAt: string;
  updatedAt: string;
  receiverIds: string[];
  shouldShowSalary: boolean;
  educationLevel?: EducationLevel;
  fraudReportFlag?: FraudReportFlag | null;
  HierarchicalJobCategoryId?: string;
  HierarchicalJobCategory?: HierarchicalJobCategory;
  traceInfo?: string;
}
