import axios from 'axios';
import { includes } from 'lodash';

import type { ClassTypeName } from '../global/models/Event';
import { ClassTypeNames } from '../modules/Events/constants';
import { PageCategory } from './enums/pageCategories';

export async function getIsTokenScoped() {
  const response = await axios.get('/auth/scope');

  return Boolean(response.data);
}

// Converts accented letters to unaccented
export function unaccentTerm(term: string) {
  return typeof term === 'string' ? unaccentVietnamese(term) : '';
}

// VN accented characters are copied from https://github.com/huynhsamha/jsrmvi/blob/master/lib/index.js
function unaccentVietnamese(term: string) {
  return typeof term === 'string'
    ? term
        .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
        .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
        .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
        .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
        .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
        .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
        .replace(/đ/g, 'd')
        .replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
        .replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
        .replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
        .replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
        .replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
        .replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
        .replace(/Đ/g, 'D')
    : '';
}

export function showDateTimeInExpertClassEvent(
  eventClassTypeName: ClassTypeName | undefined
): boolean {
  return !includes([ClassTypeNames.ON_DEMAND], eventClassTypeName);
}

export const PageCategoryMap = {
  '/': PageCategory.GLINTS_HOMEPAGE,
  '/companies': PageCategory.COMPANIES,
  '/companies/id': PageCategory.COMPANY_DETAIL,
  '/expert-class': PageCategory.EXPERTCLASS,
  '/opportunities/jobs/bookmarked': PageCategory.JOBS_BOOKMARKED,
  '/opportunities/jobs/explore': PageCategory.JOBS_EXPLORE,
  '/opportunities/jobs/id': PageCategory.JOB_DETAIL,
  '/opportunities/jobs/recommended': PageCategory.JOBS_FYP,
};

/**
 * Returns the page category for the given pathname
 * @param pathname
 */
export const pageCategoryMapper = (pathname: string) => {
  return (
    PageCategoryMap[pathname as keyof typeof PageCategoryMap] ??
    PageCategory.OTHERS
  );
};

// create searching Google Map URL with address
export const parseAddressMapURL = (address: string) => {
  const encodeAddressURI = encodeURIComponent(address);
  return `https://www.google.com/maps/search/?api=1&query=${encodeAddressURI}`;
};

export type SocialMediaMap = {
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  twitter?: string;
};

export const parseSocialURL = (socialSite: SocialMediaMap): SocialMediaMap => {
  const result: SocialMediaMap = {};
  const URLPrefix = 'https://www';

  if (socialSite?.facebook) {
    result.facebook = `${URLPrefix}.facebook.com/${socialSite.facebook}`;
  }
  if (socialSite?.instagram) {
    result.instagram = `${URLPrefix}.instagram.com/${socialSite.instagram}`;
  }
  if (socialSite?.linkedin) {
    result.linkedin = `${URLPrefix}.linkedin.com/company/${socialSite.linkedin}`;
  }
  if (socialSite?.twitter) {
    result.twitter = `${URLPrefix}.twitter.com/${socialSite.twitter}`;
  }

  return result;
};
